import React from "react";
import {
  DotsLoader,
  FormInput,
  FormSelectInput,
  Modal,
} from "../../../components";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import TagsInput from "../../../components/atoms/CustomTagsInput";
import api from "../../../services/api";
import { MdCancel } from "react-icons/md";
import { get_dids } from "../../../features/didSlice";
function CopyDidsForm({
  editingRecord,
  onCancelForm,
  modalTitle,
  callManu,
  paginationModel,
  sqlQuerry,
}) {
  const dispatch = useDispatch();
  const { ccCards, ccProviders } = useSelector((state) => state.dids);
  const inbound = useSelector((state) => state.inbound);
  const { dialerUsers } = useSelector((state) => state.users);
  const { record } = inbound;
  const [errors, setErrors] = React.useState([]);
  const [loader, setLoader] = React.useState(false);
  const [dids, setDids] = React.useState([]);
  const [success, setSuccess] = React.useState([]);
  const [failed, setFailed] = React.useState([]);
  const [isConnexCx, setIsConnexCx] = React.useState(false);
  let user = localStorage.getItem("user");
  user = user ? JSON.parse(user) : null;
  let initialValues = {
    did_pattern: "",
    did_description: "",
    record_call: "Y",
    did_route: "EXTEN",
    group_id: "",
    menu_id: "",
    extension: "",
    user: "",
    voicemail_ext: "",
    is_switch: 0,
    customer_card: user?.conexcs_settings?.customer_card || "",
    provider: user?.conexcs_settings?.provider || "",
    provider_card: user?.conexcs_settings?.provider_card || "",
    cx: user?.conexcs_settings?.cx || "",
    destination_ip: user?.conexcs_settings?.destination_ip || "",
    dial_out_prefix: user?.conexcs_settings?.dial_out_prefix || "",
  };

  if (editingRecord) {
    const {
      did_pattern,
      did_description,
      record_call,
      did_route,
      group_id,
      did_id,
      menu_id,
      extension,
      user,
      voicemail_ext,
    } = editingRecord;
    initialValues = {
      id: did_id,
      did_pattern,
      did_description,
      record_call,
      did_route,
      group_id,
      menu_id,
      extension: extension
        ? extension?.replace(user?.conexcs_settings?.dial_out_prefix, "")
        : "",
      is_switch: 0,
      user: user,
      voicemail_ext: voicemail_ext,
      customer_card: user?.conexcs_settings?.customer_card || "",
      provider: user?.conexcs_settings?.provider || "",
      provider_card: user?.conexcs_settings?.provider_card || "",
      cx: user?.conexcs_settings?.cx || "",
      destination_ip: user?.conexcs_settings?.destination_ip || "",
      dial_out_prefix: user?.conexcs_settings?.dial_out_prefix || "",
    };
  }

  function validateForm(data) {
    const errors = [];
    if (isConnexCx && !data?.customer_card) {
      errors.push({ customer_card: "customer_card is required" });
    }
    if (isConnexCx && !data?.provider_card) {
      errors.push({ provider_card: '"provider_card" is required' });
    }
    if (isConnexCx && !data?.provider) {
      errors.push({ provider: '"provider" is required' });
    }
    setErrors(errors);
    return errors;
  }

  const handleSubmit = async (values) => {
    if (isConnexCx && !values.cx)
      return toast.error(
        "Some required information is missing to add DIDs on switch, please contact to the administrator"
      );
    const message = editingRecord ? "updated" : "created";

    const errors = validateForm(formik.values);
    if (isConnexCx && errors?.length) return;
    const {
      customer_card,
      provider,
      provider_card,
      cx,
      destination_ip,
      dial_out_prefix,
    } = values;
    const payload = {
      did_id: values?.id,
      did_pattern: dids,
      is_switch: isConnexCx,
      customer_card,
      provider,
      provider_card,
      cx,
      destination_ip,
      dial_out_prefix,
    };
    setLoader(true);
    try {
      const res = await api.post("/api/cc/dids/copy_did", payload);
      if (res?.data?.status && !res?.data?.failed?.length) {
        toast.success(res?.data?.message || "DID Successfully Coppied");
        dispatch(
          get_dids({
            page: paginationModel?.page,
            size: paginationModel?.pageSize,
            sqlwhere: sqlQuerry,
          })
        );
        onCancelForm();
      } else if (res?.data?.failed?.length) {
        setSuccess(res?.data?.success);
        setFailed(res?.data?.failed);
        setDids((prevDids) =>
          prevDids?.filter((item) => !res?.data?.success?.includes(item))
        );
        // setDids(prevDids => prevDids?.filter(item => !success.includes(item)));
        toast.error(
          (res?.data?.message === "DID Successfully Coppied"
            ? "All DIDs couldn't be copied"
            : res?.data?.message) || "All DIDs couldn't be copied"
        );
      }
      setLoader(false);
    } catch (error) {
      setLoader(false);
      if (Array.isArray(error.payload) || typeof error.payload === "object") {
        setErrors(error.payload);
      } else {
        toast.error(
          error.payload ? error.payload : `DID couldn't be ${message}`
        );
      }
    }
  };
  const formik = useFormik({
    initialValues,
    validateOnBlur: true,
    onSubmit: handleSubmit,
  });
  function copyInvalidData() {
    // Get all the badge elements that contain the invalid data
    const badges = document.querySelectorAll(".bg-danger");
    // Extract the text from each badge element and join them with a separator
    const dataToCopy = Array.from(badges)
      ?.map((badge) => badge.textContent)
      ?.join("\n");
    // Copy the text to the clipboard
    const textarea = document.createElement("textarea");
    textarea.value = dataToCopy;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand("copy");
    document.body.removeChild(textarea);
    toast.success("All invalid DIDs have been copied to the clipboard!");
  }
  const providerCardsOption = ccCards?.vendors?.filter(
    ({ direction, company_id }) =>
      direction === "origination" && company_id === +formik.values.provider
  );
  return (
    <Modal
      isUpdate={!!editingRecord}
      title={modalTitle}
      onCancelModal={onCancelForm}
      formIsValid={formik.isValid}
      onSubmit={formik.handleSubmit}
      isLoading={loader}
      onClick={onCancelForm}
    >
      {loader ? <DotsLoader /> : null}
      <div className="grid md:grid-cols-4 gap-x-5 mt-5 mx-2.5">
        <div className="mb-3">
          <FormInput
            errors={errors}
            name="did_description"
            label="Site Name"
            formik={formik}
            readOnly
          />
        </div>
        <div className="mb-3">
          <FormSelectInput
            errors={errors}
            name="did_route"
            label="DID Route"
            formik={formik}
            options={[
              { value: "EXTEN", label: "EXTEN" },
              { value: "IN_GROUP", label: "IN_GROUP" },
              { value: "CALLMENU", label: "CALLMENU" },
              { value: "AGENT", label: "Agent" },
            ]}
            valueProp="value"
            labelProp="label"
            disabled={true}
          />
        </div>
        {formik.values.did_route === "IN_GROUP" ? (
          <div>
            <FormSelectInput
              errors={errors}
              name="group_id"
              label="In-Group ID"
              formik={formik}
              options={record}
              valueProp="group_id"
              labelProp="group_name"
              disabled={true}
            />
          </div>
        ) : null}
        {formik.values.did_route === "EXTEN" ? (
          <div className="mb-">
            <FormInput
              errors={errors}
              name="extension"
              label="Extension"
              formik={formik}
              readOnly
            />
          </div>
        ) : null}
        {formik.values.did_route === "CALLMENU" ? (
          <div>
            <FormSelectInput
              errors={errors}
              name="menu_id"
              label="Call Menu"
              formik={formik}
              options={callManu}
              valueProp="menu_id"
              labelProp="menu_name"
            />
          </div>
        ) : null}
        {formik.values.did_route === "AGENT" ? (
          <div>
            <FormSelectInput
              errors={errors}
              name="user"
              label="Agent"
              formik={formik}
              options={dialerUsers}
              valueProp="value"
              labelProp="label"
              onChange={(e) => {
                formik?.setFieldValue("user", e);
                formik?.setFieldValue(
                  "voicemail_ext",
                  dialerUsers?.find(({ value }) => value === e)?.dialer_phone
                );
              }}
            />
          </div>
        ) : null}
        <div className="cursor-pointer flex col-span-4 mb-2">
          <input
            type="checkbox"
            id="showpass"
            name="showpass"
            className="!ml-3 form-checkbox h-4 w-4 text-primary-100 rounded-sm focus:ring-0 cursor-pointer"
            onChange={() => setIsConnexCx(!isConnexCx)}
            checked={isConnexCx}
          />
          <label
            htmlFor="showpass"
            className="ml-[6px] text-black font-pop text-[12px] cursor-pointer"
          >
            Want to add DIDs on switch?
          </label>
        </div>
        {isConnexCx && user?.role === "SUPER ADMINISTRATOR" ? (
          <div className="col-span-4 grid grid-cols-3 gap-x-2 my-2">
            <div>
              <FormSelectInput
                errors={errors}
                name="customer_card"
                label="Customer Card"
                formik={formik}
                options={[
                  ...ccCards?.customers?.filter(
                    ({ direction }) => direction === "origination"
                  ),
                ]}
                valueProp="id"
                labelProp="name"
              />
            </div>
            <div>
              <FormSelectInput
                errors={errors}
                name="provider"
                label="Provider"
                formik={formik}
                options={ccProviders}
                valueProp="id"
                labelProp="name"
              />
            </div>
            <div>
              <FormSelectInput
                errors={errors}
                name="provider_card"
                label="Provider Card"
                formik={formik}
                options={providerCardsOption}
                valueProp="id"
                labelProp="name"
              />
            </div>
          </div>
        ) : null}
        <div className="col-span-4 mt-2">
          <TagsInput
            tags={dids}
            setTags={setDids}
            placeholder="Add DID extensions"
            label="Add New DIDs"
            isFloat
          />
        </div>
      </div>
      {failed?.length > 0 && (
        <div className="flex flex-row">
          <div className="flex flex-col w-full mt-2">
            <small>Successfully saved: {success?.length}</small>
            <div className="w-full form-control h-auto d-inline-flex flex-wrap min-h-[38px] mr-2">
              {success?.map((val, index) => {
                return (
                  <div
                    className="badge me-1 pe-1 justify-content-between badge me-1 pe-1 justify-content-between bg-success !text-white !mr-2 my-0.5 h-[26.39px]"
                    key={index}
                  >
                    {val}
                    <button className="border-0 bg-transparent ps-auto pe-0">
                      <MdCancel
                        size={20}
                        className="ml-1 cursor-pointer text-white"
                        // onClick={() => {
                        //   dispatch(removeFromValidLoc(val));
                        // }}
                      />
                    </button>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="flex flex-col w-full mt-2 ml-2">
            <div className="flex felx-row justify-between">
              <div>
                <small>Failed to save: {failed?.length}</small>
              </div>
              <div>
                <small
                  onClick={copyInvalidData}
                  className="cursor-pointer hover:underline text-blue-600"
                >
                  Copy
                </small>
                <small
                  onClick={() => setFailed([])}
                  className="ml-2 cursor-pointer hover:underline text-blue-600"
                >
                  Clear
                </small>
              </div>
            </div>

            <div className="form-control h-auto d-inline-flex flex-wrap min-h-[44.38px]">
              {failed?.map((val, index) => {
                return (
                  <div
                    className="badge me-1 pe-1 justify-content-between badge me-1 pe-1 justify-content-between bg-danger !text-white !mr-2 my-0.5 h-[26.39px]"
                    key={index}
                  >
                    {val}
                    <button className="border-0 bg-transparent ps-auto pe-0">
                      <MdCancel
                        size={20}
                        className="ml-1 cursor-pointer text-white"
                        // onClick={() =>
                        //   setInValidData((prevState) =>
                        //     prevState.filter((item) => item !== val)
                        //   )
                        // }
                      />
                    </button>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </Modal>
  );
}

export default CopyDidsForm;
