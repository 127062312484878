import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
} from "@mui/x-data-grid-pro";
import React from "react";
import { FaCheck, FaSearch, FaTrash } from "react-icons/fa";
import { IoClose } from "react-icons/io5";
import CustomDropdown from "../../atoms/CustomDropdown";
import RttIcon from "@mui/icons-material/Rtt";
import { Button } from "@mui/material";
import { MultiSelect } from "primereact/multiselect";

function MUIToolbar({
  isHideColumnButton,
  isHideDensityButton,
  isHideFilterButton,
  isBulkUpdate,
  fieldsArray,
  showSelectAll,
  selectedItem,
  isSelectAll,
  setIsSelectAll,
  setSelectedData,
  handleBulkSave,
  onCancel,
  applyFilter,
  handleBulkDelete,
}) {
  const handleAction = () => {
    if (isSelectAll && showSelectAll) {
      setSelectedData([]);
      setIsSelectAll(!isSelectAll);
      onCancel();
    } else if (isSelectAll && showSelectAll) {
      setIsSelectAll(!isSelectAll);
    } else {
      setSelectedData([]);
    }
  };
  return (
    <GridToolbarContainer className="py-2">
      {showSelectAll && selectedItem ? (
        <span className="text-sm font-semibold bg-[#fcebc5] py-1 px-1.5">
          {`${isSelectAll ? "All " : ""}${selectedItem} items are selected `}
          <span
            className="text-blue-600 hover:underline cursor-pointer"
            onClick={handleAction}
          >
            {isSelectAll ? "Clear Selection?" : "Select All?"}
          </span>
        </span>
      ) : null}
      {!showSelectAll && selectedItem ? (
        <span className="text-sm font-semibold bg-[#fcebc5] py-1 px-1.5">
          {`${isSelectAll ? "All " : ""}${selectedItem} items are selected `}
          <span
            className="text-blue-600 hover:underline cursor-pointer"
            onClick={handleAction}
          >
            {"Clear Selection?"}
          </span>
        </span>
      ) : null}
      {!isHideColumnButton ? (
        <GridToolbarColumnsButton className="!text-[#042a42] btnSecondary" />
      ) : null}
      {!isHideDensityButton ? (
        <GridToolbarDensitySelector className="!text-[#042a42] btnSecondary" />
      ) : null}
      {!isHideFilterButton ? (
        <GridToolbarFilterButton className="!text-[#042a42] btnSecondary" />
      ) : null}
      {applyFilter ? (
        <Button
          variant="text"
          onClick={applyFilter}
          startIcon={<FaSearch size={16} />}
          sx={{ fontSize: "0.8125rem" }}
          className="!text-[#042a42] btnSecondary"
        >
          Apply filter
        </Button>
      ) : null}
      {handleBulkDelete && selectedItem ? (
        <Button
          variant="text"
          onClick={handleBulkDelete}
          startIcon={<FaTrash size={16} />}
          sx={{ fontSize: "0.8125rem" }}
          className="!text-[#042a42] btnSecondary"
        >
          Delete Selected
        </Button>
      ) : null}
      {isBulkUpdate && selectedItem ? (
        <div className="flex w-fit  after:content-[''] after:absolute after:h-8 after:w-1 after:bg-gradient-to-r after:from-black/[0.1] after:to-black/[0]">
          <div className="ml-2 flex space-x-2 flex-row items-center w-full">
            {fieldsArray?.length &&
              fieldsArray?.map((item, index) => {
                const {
                  placeholder,
                  onChange,
                  value,
                  name,
                  options,
                  valueProp,
                  labelProp,
                  isHidden,
                  isSelect,
                  isInput,
                  isMulti,
                  isCheckbox,
                  id,
                } = item;
                return !isHidden ? (
                  <>
                    {isSelect ? (
                      <CustomDropdown
                        placeholder={placeholder}
                        onChange={onChange}
                        value={value}
                        name={name}
                        options={options}
                        valueProp={valueProp}
                        labelProp={labelProp}
                      />
                    ) : isInput ? (
                      <div className="p-1 whitespace-nowrap font-medium text-center capitalize !shadow-md dark:shadow-white/5 !shadow-gray-300 rounded cursor-pointer text-menu">
                        <span className="flex items-center truncate w-full">
                          <RttIcon
                            className=" cursor-pointer mr-2"
                            size={18}
                            fontSize="small"
                          />
                          <input
                            className="border p-0.5"
                            value={value}
                            placeholder={placeholder}
                            name={name}
                            onChange={(e) => onChange(e)}
                          />
                        </span>
                      </div>
                    ) : isMulti ? (
                      <MultiSelect
                        placeholder={placeholder}
                        name={name}
                        options={options}
                        optionLabel={labelProp}
                        optionValue={valueProp}
                        filter
                        maxSelectedLabels={1}
                        value={value}
                        onChange={onChange}
                        className="h-[38px] mb-2 w-full !rounded-[4px] focus:!shadow-none border-[#e5e7eb] hover:border-[#ced4da]"
                      />
                    ) : isCheckbox ? (
                      <div className="flex">
                        <input
                          type="checkbox"
                          id={id}
                          name={name}
                          checked={value}
                          onChange={onChange}
                          className="cursor-pointer form-checkbox h-5 w-5 text-primary-100 focus:ring-0"
                        />
                        <label htmlFor={id} className="ml-2 cursor-pointer">
                          {placeholder}
                        </label>
                      </div>
                    ) : null}
                  </>
                ) : null;
              })}
            <div className="flex flex-row space-x-1">
              <button
                className="bg-primary-100 text-white w-[25px] h-[25px] rounded-full flex justify-center items-center"
                onClick={handleBulkSave}
              >
                <FaCheck />
              </button>
              <button
                className="bg-menu text-white w-[25px] h-[25px] rounded-full flex justify-center items-center"
                onClick={onCancel}
              >
                <IoClose size={20} />
              </button>
            </div>
          </div>
        </div>
      ) : null}
    </GridToolbarContainer>
  );
}

export default MUIToolbar;
