import React from "react";
import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import { Layout } from "./components";
import { useSelector } from "react-redux";
import {
  Login,
  AllowedIp,
  Merchant,
  Tag,
  Lead,
  Job,
  User,
  EmailTemplate,
  PaymentGateway,
  JobStatus,
  DeclineReason,
  SMSTemplate,
  LoginLogs,
  Roles,
  CardType,
  Industry,
  JobCategory,
  ClientForm,
} from "./views/";
import InboundGroup from "./views/CallCenter/InGroup";
import LocationForm from "./views/Client/View/LocationForm";
import CreateJobForm from "./views/Job/AddUpdateForm";
import QuotePricing from "./views/QuotePricing";
import ForgotPassword from "./views/Login/forgetPassword";
import ConfirmPassword from "./views/Login/confirmPassword";
import Campaigns from "./views/CallCenter/Campaigns";
import DIDsGroups from "./views/CallCenter/DIDs";
import Scheduler from "./views/Scheduler";
import JobsOnMap from "./views/Map";
import MapWithRadius from "./views/Map/GoogleMap";
import Vendors from "./views/Vendors";
import RealTime from "./views/RealTime";
import ReportsDashboard from "./reports";
import CommissionReport from "./reports/Commision";
import AgentlessReport from "./reports/Agentless";
import ClosingReport from "./reports/Closing";
import LastTouchReport from "./reports/LastTouch";
import MultiLoginReport from "./reports/MultiLogin";
import SaleReport from "./reports/Sale";
import TransactionReport from "./reports/Transactions";
import OnlineUserReport from "./reports/OnlineUsers";
import GeneralSettings from "./views/GeneralSetting";
import Providers from "./views/Provider";
import CallCenterMenu from "./views/CallCenter";
import CallCenters from "./views/CallCenter/CallCenters";
import Tasks from "./views/Tasks";
import Dashboard from "./views/Dashboard";
import StatisticsReports from "./reports/StatisticsReports";
import Balances from "./reports/Balances";
import GeoLocation from "./views/GeoLocation/GeoLocation";
import GeoIndustry from "./views/GeoIndustry";
import GeoVendors from "./views/GeoVendors";
import GeoLocationList from "./views/GeoLocation";
import GeoReport from "./views/GeoLocation/Report";
import DistanceTable from "./views/GeoLocation/DistanceTable";
import GeoStates from "./views/GeoStates";
import GeoCities from "./views/GeoCities";
import ScrubDomains from "./views/GeoLocation/ScrubDomains";
import AgentPerformance from "./views/CallCenter/Reports/AgentPerformanceReport";
import CallSummary from "./views/CallCenter/Reports/CallSummary";
import ScrubReport from "./views/GeoLocation/ScrubReport";
import Tlds from "./views/Tlds";
import DomainBuying from "./views/DomainCenter/Buying";
import InBoundReport from "./views/CallCenter/Reports/InBound";
import BatchReport from "./views/DomainCenter/Batch Report";
import BatchViewModal from "./views/DomainCenter/Batch Report/ViewBatch";
import AssignTech from "./views/GeoLocation/AssignTech";
import ServiceAreas from "./views/GeoIndustry/ServiceAreas";
import VendorsIndustry from "./views/VendorIndustry";
import UpdateVendorsIndustry from "./views/VendorIndustry/AddUpdatePage";
import UpdateVendorsLocations from "./views/Vendors/UpdateLocations";
import MapComponent from "./views/Map/GoogleMao2";
import Conversation from "./views/Conversation";
import CompnaySummary from "./reports/8x8/CompnaySummary";
import ExtensionSummary from "./reports/8x8/ExtensionSummary";
import CallDetailRecords from "./reports/8x8/CallDetailRecords";
import CallsByDIDs from "./reports/8x8/CallsByDIDs";
import VendorLocationsReport from "./reports/Domains/VendorLocationsReport";
import DomainDataReport from "./reports/Domains/DomainDataReport";
import OOAStatusReport from "./reports/OOA";
import GoogleConsole from "./views/GoogleConsoleList";
import MajorCities from "./reports/Domains/MajorCities";
import MultipleDomain from "./views/DomainCenter/MultipleDomains";
import DomainLocationReport from "./views/DomainCenter/DomainsLocationDetail";
import TestingLinks from "./views/DomainCenter/TestLinks";
import MissedCallsReport from "./views/CallCenter/Reports/MissedCalls";
import PurchaseRegistrar from "./views/DomainCenter/PurchaseRegistrar";
import APIsReport from "./reports/APIsReport";
import IndustrySummary from "./views/Industries/IndustrySummary";
import Settings from "./views/Settings";
import RestrictedWords from "./views/RestrictedWords";
import GoogleEmailAccounts from "./views/GoogleEmailAccounts";
import GoogleServer from "./views/Servers";
import CallMenu from "./views/CallCenter/CallMenu";
import AudioUpload from "./views/CallCenter/AudioUpload";
import UpdateCallMenu from "./views/CallCenter/CallMenu/UpdateCallMenu";
import CronFunctions from "./views/CronFunctions";
import IndustryDatabase from "./views/IndustryDatabase";
import TestingDomains from "./views/DomainCenter/TestingDomains";
import NationSites from "./views/NationSites";
import NationDidListing from "./views/NationSites/DidListing";
import NationTollfreeDidListing from "./views/NationSites/TollfreeDidListing";
import DomainRunner from "./views/DomainRunners";
import RegistrarAccounts from "./views/DomainCenter/RegistrarAccounts";
import AlternateDomainPhone from "./views/DomainCenter/AlternateDomainsPhone";
import DomainNumbers from "./views/DomainCenter/DomainNumbers";
import VirtualHost from "./views/DomainCenter/VirtualHost";
import PendingDomainsPhone from "./views/DomainCenter/PendingDomainsPhone";
import PullToServer from "./views/DomainCenter/PullToServer";
import NationSiteZips from "./views/NationSiteZips";
import DatabaseTables from "./views/IndustryDatabase/DatabaseTables";
import DatabaseRows from "./views/IndustryDatabase/DatabaseRows";
import SpamNumbers from "./views/CallCenter/SpamNumbers";
import DomainKeywords from "./views/DomainCenter/SERPReport/DomainRank/DomainKeywords";
import KeywordsBatches from "./views/DomainCenter/SERPReport/DomainRank/KeywordBatches";
import SERPKeywordsSearches from "./views/DomainCenter/SERPReport/DomainRank/Searches";
import Domains from "./views/DomainCenter/SERPReport/DomainRank/Domains";
import InBoundForwardingReport from "./views/CallCenter/Reports/InBoundForwarding";
import InvoiceReport from "./reports/InvoiceReport";
import InteliquentNumbers from "./views/DomainCenter/InteliquentNumbers";
import VendorBalance from "./reports/VendorBalance";
import WebQueryReport from "./reports/WebQueryReport";
import VendorJobsReport from "./reports/VendorJobsReport";
import DIDsInventory from "./views/DIDInventory";
import DomainsReport from "./views/Domains";
import VendorJobs from "./views/VendorViews/VendorsJobs";
import VendorsInvoice from "./views/VendorViews/VendorsInvoice";
import IndustryWiseStatus from "./reports/IndustryWiseStatus";
import CoverPage from "./views/Login/CoverPage";
import NationSiteVendors from "./views/NationSites/Vendors";
import ServiceHours from "./views/NationSites/ServiceHours";
import NationSiteRating from "./views/NationSites/Rating";
import Permissions from "./views/Permissions";
import UserPermissions from "./views/Roles/UserPermissions";
import LeadListing from "./views/LeadListing";
const Router = () => {
  const { user } = useSelector((state) => state.auth);
  const permissions = user?.menu_permissions;
  const hostname = window.location.hostname === "towingwiz.com";
  let routes;
  if (!user) {
    routes = [
      // { path: "/", element: <CoverPage /> },
      { path: "/login", element: <Login /> },
      { path: "/client/service_form", element: <ClientForm /> },
      { path: "/client/location_form", element: <LocationForm /> },
      { path: "/forgot_password", element: <ForgotPassword /> },
      { path: "/reset/:token", element: <ConfirmPassword /> },
      { path: "/resetVendor/:token", element: <ConfirmPassword /> },
      {
        path: "*",
        element: <Navigate to={"/login"} replace />,
      },
    ];
  } else {
    routes = [
      { path: "/map2", element: <MapWithRadius />, permission: "Map" },
      { path: "/map3", element: <MapComponent />, permission: "Map" },
      { path: "/leads", element: <LeadListing />, permission: "Lead" },
      { path: "/leads/add", element: <ClientForm />, permission: "Lead" },
      {
        path: "/conversations",
        element: <Conversation />,
        permission: "Conversations",
      },
      {
        path: "/leads/update/:id",
        element: <ClientForm />,
        permission: "Lead",
      },
      {
        path: "/vendor_jobs",
        element: <VendorJobs />,
        permission: "Vendor Jobs",
      },
      {
        path: "/vendor_invoices",
        element: <VendorsInvoice />,
        permission: "Vendor Invoices",
      },
      { path: "/calendar", element: <Scheduler />, permission: "Calendar" },
      { path: "/map", element: <JobsOnMap />, permission: "Map" },
      {
        path: "/industry_database",
        element: <IndustryDatabase />,
        permission: "Industry Database",
      },
      {
        path: "/industry_database/:dbName",
        element: <DatabaseTables />,
        permission: "Industry Database",
      },
      {
        path: "/industry_database/:dbName/:tableName/:tagName?",
        element: <DatabaseRows />,
        permission: "Industry Database",
      },
      { path: "/dashboard", element: <Dashboard />, permission: "Dashboard" },
      { path: "/jobs", element: <Job />, permission: "Jobs" },
      {
        path: `/jobs?searchBy=:searchBy&searchValue=:searchValue`,
        element: <Job />,
        permission: "Jobs",
      },

      { path: "/jobs/create", element: <CreateJobForm />, permission: "Jobs" },
      {
        path: "/jobs/update/:id",
        element: <CreateJobForm />,
        permission: "Jobs",
      },
      {
        path: "/jobs/update/preview/:id",
        element: <CreateJobForm />,
        permission: "Jobs",
      },

      {
        path: "/settings/allowedIp",
        element: <AllowedIp />,
        permission: "Allowed IP",
      },
      { path: "/settings/users", element: <User />, permission: "User" },
      {
        path: "/settings/vendors",
        element: <Vendors />,
        permission: "Vendors",
      },
      {
        path: "/settings/vendors/add",
        element: <UpdateVendorsLocations />,
        permission: "Vendors",
      },
      {
        path: "/settings/vendors/update/:id",
        element: <UpdateVendorsLocations />,
        permission: "Vendors",
      },
      {
        path: "/settings/vendors_industry",
        element: <VendorsIndustry />,
        permission: "Vendors",
      },
      {
        path: "/settings/vendors_industry_update",
        element: <UpdateVendorsIndustry />,
        permission: "Vendors",
      },
      {
        path: "/settings/merchants",
        element: <Merchant />,
        permission: "Merchants",
      },
      { path: "/settings/tags", element: <Tag />, permission: "Tags" },
      {
        path: "/settings/emailTemplates",
        element: <EmailTemplate />,
        permission: "Email Templates",
      },
      {
        path: "/settings/paymentGateway",
        element: <PaymentGateway />,
        permission: "Payment Gateway",
      },
      {
        path: "/settings/jobStatus",
        element: <JobStatus />,
        permission: "Job Status",
      },
      {
        path: "/settings/smsTemplates",
        element: <SMSTemplate />,
        permission: "SMS Templates",
      },
      {
        path: "/settings/declineReasons",
        element: <DeclineReason />,
        permission: "Decline Reason",
      },
      { path: "/settings/roles", element: <Roles />, permission: "User Roles" },
      {
        path: "/settings/tasks",
        element: <Tasks />,
        permission: "User Permissions",
      },
      {
        path: "/settings/cardtype",
        element: <CardType />,
        permission: "Card Types",
      },
      {
        path: "/settings/industries",
        element: <Industry />,
        permission: "Industry",
      },
      {
        path: "/settings/jobCategory",
        element: <JobCategory />,
        permission: "Industry Category",
      },
      {
        path: "/settings/generalSettings",
        element: <GeneralSettings />,
        permission: "General Settings",
      },
      {
        path: "/settings/providers",
        element: <Providers />,
        permission: "Payment Providers",
      },
      {
        path: "/settings/quote_pricing",
        element: <QuotePricing />,
        permission: "Quoting",
      },
      {
        path: "/settings/restricted_words",
        element: <RestrictedWords />,
        permission: "Restricted Words",
      },
      {
        path: "/settings/cron_functions",
        element: <CronFunctions />,
        permission: "Cron Functions",
      },

      {
        path: "/callcenter",
        element: <CallCenterMenu />,
        permission: "Call Center",
      },
      { path: "/settings", element: <Settings />, permission: "Settings" },
      {
        path: "/callcenter/inbound_groups",
        element: <InboundGroup />,
        permission: "Ring Groups",
      },
      {
        path: "/callcenter/campaigns",
        element: <Campaigns />,
        permission: "Campaigns",
      },
      { path: "/callcenter/dids", element: <DIDsGroups />, permission: "DIDs" },
      {
        path: "/callcenter/call_centers",
        element: <CallCenters />,
        permission: "Call Centers",
      },
      {
        path: "/callcenter/audio_files",
        element: <AudioUpload />,
        permission: "Audio Files",
      },
      {
        path: "/callcenter/call_menu",
        element: <CallMenu />,
        permission: "Call Menu",
      },
      {
        path: "/callcenter/call_menu/:id",
        element: <UpdateCallMenu />,
        permission: "Call Menu",
      },
      {
        path: "/callcenter/spam_numbers",
        element: <SpamNumbers />,
        permission: "Spam Numbers",
      },

      {
        path: "callcenter/reports/agent_performance_report",
        element: <AgentPerformance />,
        permission: "Agent Performance",
      },
      {
        path: "callcenter/reports/call_summary_report",
        element: <CallSummary />,
        permission: "Call Summary",
      },
      {
        path: "callcenter/reports/call_logs",
        element: <InBoundReport />,
        permission: "Call Logs",
      },
      {
        path: "callcenter/reports/inbound_forwarding_report",
        element: <InBoundForwardingReport />,
        permission: "Inbound Forwarding",
      },
      {
        path: "callcenter/reports/missedcall_report",
        element: <MissedCallsReport />,
        permission: "Missed Call Report",
      },

      {
        path: "/reports/callcenter/reports/agent_performance_report",
        element: <AgentPerformance />,
        permission: "Agent Performance",
      },
      {
        path: "/reports/callcenter/reports/call_summary_report",
        element: <CallSummary />,
        permission: "Call Summary",
      },
      {
        path: "/reports/callcenter/reports/call_logs",
        element: <InBoundReport />,
        permission: "Call Logs",
      },
      {
        path: "/reports/callcenter/reports/missedcall_report",
        element: <MissedCallsReport />,
        permission: "Missed Call Report",
      },
      {
        path: "/client/service_form",
        element: <ClientForm />,
        permission: "Lead",
      },
      {
        path: "/client/location_form",
        element: <LocationForm />,
        permission: "Lead",
      },
      { path: "/reports", element: <ReportsDashboard />, permission: "All" },
      { path: "/reports/loginLogs", element: <LoginLogs />, permission: "All" },
      {
        path: "/reports/commission",
        element: <CommissionReport />,
        permission: "All",
      },
      {
        path: "/reports/vendors_invoice",
        element: <InvoiceReport />,
        permission: "All",
      },
      {
        path: "/reports/job_progress_report",
        element: <VendorJobsReport />,
        permission: "All",
      },
      {
        path: "/reports/vendors_balance",
        element: <VendorBalance />,
        permission: "All",
      },
      {
        path: "/reports/agentless",
        element: <AgentlessReport />,
        permission: "All",
      },
      {
        path: "/reports/closing",
        element: <ClosingReport />,
        permission: "All",
      },
      {
        path: "/reports/last_touch",
        element: <LastTouchReport />,
        permission: "All",
      },
      {
        path: "/reports/multi_login",
        element: <MultiLoginReport />,
        permission: "All",
      },
      { path: "/reports/sale", element: <SaleReport />, permission: "All" },
      {
        path: "/reports/transaction",
        element: <TransactionReport />,
        permission: "All",
      },
      {
        path: "/reports/online_users",
        element: <OnlineUserReport />,
        permission: "All",
      },
      {
        path: "/reports/statistics_reports",
        element: <StatisticsReports />,
        permission: "All",
      },

      {
        path: "/reports/company_summary",
        element: <CompnaySummary />,
        permission: "All",
      },
      {
        path: "/reports/extension_summary",
        element: <ExtensionSummary />,
        permission: "All",
      },
      {
        path: "/reports/call_detail_records",
        element: <CallDetailRecords />,
        permission: "All",
      },
      {
        path: "/reports/calls_by_did",
        element: <CallsByDIDs />,
        permission: "All",
      },
      {
        path: "/reports/vendor_location_reports",
        element: <VendorLocationsReport />,
        permission: "All",
      },
      {
        path: "/reports/domain_data_report",
        element: <DomainDataReport />,
        permission: "All",
      },
      {
        path: "/reports/ooa_status_report",
        element: <OOAStatusReport />,
        permission: "All",
      },
      {
        path: "/reports/major_cities",
        element: <MajorCities />,
        permission: "All",
      },
      {
        path: "/reports/api_report",
        element: <APIsReport />,
        permission: "All",
      },
      {
        path: "/reports/business_status",
        element: <IndustryWiseStatus />,
        permission: "All",
      },
      { path: "/reports/balances", element: <Balances />, permission: "All" },
      {
        path: "/reports/web_query_report",
        element: <WebQueryReport />,
        permission: "All",
      },
      {
        path: "/geolocation/locations_list",
        element: <GeoLocationList />,
        permission: "Locations",
      },
      {
        path: "/geolocation/locations",
        element: <GeoLocation />,
        permission: "Locations",
      },
      {
        path: "/geolocation/geo_industry",
        element: <GeoIndustry />,
        permission: "Geo Location",
      },
      {
        path: "/geolocation/geo_industry/service_area/:id",
        element: <ServiceAreas />,
        permission: "Geo Location",
      },
      {
        path: "/geolocation/geo_vendors",
        element: <GeoVendors />,
        permission: "Geo Location",
      },
      { path: "/realtime", element: <RealTime />, permission: "Real Time" },
      {
        path: "/update/geolocation/locations",
        element: <GeoLocation />,
        permission: "Locations",
      },
      {
        path: "/geolocation/geo_location_report",
        element: <GeoReport />,
        permission: "Report",
      },
      {
        path: "/geolocation/check_distance",
        element: <DistanceTable />,
        permission: "Check Distance",
      },
      {
        path: "/geolocation/geo_states",
        element: <GeoStates />,
        permission: "States",
      },
      {
        path: "/geolocation/geo_cities",
        element: <GeoCities />,
        permission: "Cities",
      },
      {
        path: "/geolocation/scrub_domains",
        element: <ScrubDomains />,
        permission: "Scrub Domains",
      },
      {
        path: "/geolocation/scrub_report",
        element: <ScrubReport />,
        permission: "Scrub Domains",
      },
      {
        path: "/geolocation/assign_tech/:id",
        element: <AssignTech />,
        permission: "Locations",
      },

      {
        path: "/nation_site/sites",
        element: <NationSites />,
        permission: "Site Listing",
      },
      {
        path: "/nation_site/service_hours",
        element: <ServiceHours />,
        permission: "Service Hours",
      },
      {
        path: "/nation_site/rating",
        element: <NationSiteRating />,
        permission: "Rating",
      },
      {
        path: "/nation_site/vendors",
        element: <NationSiteVendors />,
        permission: "Vendors",
      },
      {
        path: "/nation_site/sites/dids/:id",
        element: <NationDidListing />,
        permission: "Site Listing",
      },
      {
        path: "/nation_site/sites/tollfree/:id",
        element: <NationTollfreeDidListing />,
        permission: "Site Listing",
      },
      { path: "/domain_center/tlds", element: <Tlds />, permission: "TLDs" },
      {
        path: "/nation_site/nation_site_zips",
        element: <NationSiteZips />,
        permission: "Nation Site Zips",
      },
      {
        path: "/domain_center/purchase_registrar",
        element: <PurchaseRegistrar />,
        permission: "Purchased Registrar",
      },
      {
        path: "/domain_center/runners",
        element: <DomainRunner />,
        permission: "Purchased Registrar",
      },
      {
        path: "/domain_center/buying",
        element: <DomainBuying />,
        permission: "Buying",
      },
      {
        path: "/domain_center/pull_to_server",
        element: <PullToServer />,
        permission: "Pull to Server",
      },
      {
        path: "/domain_center/gsc_servers",
        element: <GoogleEmailAccounts />,
        permission: "Email Accounts",
      },
      {
        path: "/domain_center/servers",
        element: <GoogleServer />,
        permission: "Server",
      },
      {
        path: "/domain_center/inteliquent_numbers",
        element: <InteliquentNumbers />,
        permission: "Inteliquent Numbers",
      },
      {
        path: "/domain_center/batch_report",
        element: <BatchReport />,
        permission: "Batches",
      },
      {
        path: "/domain_center/batch_report/:id",
        element: <BatchViewModal />,
        permission: "Batches",
      },
      {
        path: "/google_console_list",
        element: <GoogleConsole />,
        permission: "Google Credentials",
      },
      {
        path: "/domain_center/multiple_domains",
        element: <MultipleDomain />,
        permission: "Multiple Domains",
      },
      {
        path: "/domain_rank/searches/:batchId?",
        element: <SERPKeywordsSearches />,
        permission: "Searches",
      },
      {
        path: "/domain_rank/keywords",
        element: <DomainKeywords />,
        permission: "Keywords",
      },
      {
        path: "/domain_rank/batches_list",
        element: <KeywordsBatches />,
        permission: "Batches",
      },
      {
        path: "/domain_rank/domains",
        element: <Domains />,
        permission: "Domains",
      },
      {
        path: "/domain_center/domains",
        element: <DomainsReport />,
        permission: "Purchased Domains",
      },

      {
        path: "/domain_center/pending_domains_phone",
        element: <PendingDomainsPhone />,
        permission: "Pending Phones",
      },
      {
        path: "/domain_center/domain_location_report",
        element: <DomainLocationReport />,
        permission: "Domains Location",
      },
      {
        path: "/domain_center/testing_links",
        element: <TestingLinks />,
        permission: "Domains Location",
      },
      {
        path: "/domain_center/testing_domains",
        element: <TestingDomains />,
        permission: "Testing Domains",
      },
      {
        path: "/domain_center/cloudflare_accounts",
        element: <RegistrarAccounts />,
        permission: "Cloudflare Accounts",
      },
      {
        path: "/domain_center/list_alternate_area_codes",
        element: <AlternateDomainPhone />,
        permission: "Alternate Area Codes",
      },
      {
        path: "/domain_center/domain_numbers",
        element: <DomainNumbers />,
        permission: "Domain Numbers",
      },
      {
        path: "/domain_center/virtual_host",
        element: <VirtualHost />,
        permission: "Virtual Hosts",
      },
      {
        path: "/settings/industries/industry_domains_report/:id",
        element: <IndustrySummary />,
        permission: "Industry",
      },
      {
        path: "/settings/permissions",
        permission: "All",
        element: <Permissions/>,
      },
      {
        path: "/settings/roles/user_permissions",
        permission: "All",
        element: <UserPermissions/>,
      },
      {
        path: "/dids_inventory",
        element: <DIDsInventory />,
        permission: "DIDs Inventory",
      },
      {
        path: "/lead_report",
        element: <LeadListing />,
        permission: "Lead",
      },
      {
        path: "*",
        element: (
          <Navigate
            to={
              user?.role_level === 7
                ? "/industry_database"
                : user?.role_level === 4
                ? "/Conversations"
                : user?.role?.name === "Vendor"
                ? "/vendor_jobs"
                : `/dashboard`
            }
            replace
          />
        ),
        permission: "All",
      },
    ];
  }

  return (
    <BrowserRouter>
      <Layout>
        <Routes>
          {routes.map((route, index) => {
            const foundPermission = permissions?.includes(route?.permission);
            if (foundPermission || !user || route?.permission === "All") {
              return <Route key={index} {...route} />;
            } else {
              return null;
            }
          })}
        </Routes>
      </Layout>
    </BrowserRouter>
  );
};

export default Router;
