import React from "react";
import { useEffect } from "react";
import { FaEdit } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { DotsLoader } from "../../components";
import PageHeader from "../../components/molecules/PageHeader";
import { get_jobs_data } from "../../features/jobsSlice";
import { useFormik } from "formik";
import Filter from "../../components/molecules/Filter";
import { get_users_drd } from "../../features/usersSlice";
import { get_job_status_with_job_count } from "../../features/jobStatusSlice";
import dayjs from "dayjs";
import userPermission from "../../util/userPermission";
import MUIDataTable from "../../components/molecules/DataTable/muigrid";
import { get_industry_drd } from "../../features/IndustrySlice";
import { get_geo_vendors_drd } from "../../features/GeoVendorsSlice";
import { toast } from "react-toastify";
const Jobs = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoading, record } = useSelector((state) => state.jobs);
  const { geoVendorDrd } = useSelector((state) => state.geoVendors);
  const { usersDrd } = useSelector((state) => state.users);
  const jobStatus = useSelector((state) => state.jobStatus);
  const [searchText, setSearchText] = React.useState("");
  const industry = useSelector((state) => state.industry);
  const [selectedJob, setSelectedJob] = React.useState();
  const { search } = useLocation();
  const [isButtonDisabled, setIsButtonDisabled] = React.useState(false);
  useEffect(() => {
    dispatch(get_users_drd());
    dispatch(get_industry_drd());
    dispatch(get_job_status_with_job_count());
    dispatch(get_geo_vendors_drd());
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (!search) {
      dispatch(get_jobs_data({ page: 1, size: paginationModel.pageSize }));
    } else if (search && !record?.records?.length > 0) {
      navigate(`/jobs`);
    }
    // eslint-disable-next-line
  }, [search]);

  let initialValues = {
    created_by: "",
    job_num: "",
    phone_num: "",
    to_date: "",
    from_date: "",
    job_status_id: "",
    is_closed: "",
    industry_id: "",
    vendor_id: null,
  };

  const onReset = () => {
    formik.resetForm();
    dispatch(get_jobs_data({ page: 1, size: paginationModel.pageSize }));
  };
  const formik = useFormik({
    initialValues,
    validateOnBlur: true,
    onSubmit: async (values) => {
      await dispatch(
        get_jobs_data({ ...values, page: 1, size: paginationModel.pageSize })
      );
    },
  });
  const closedOption = [
    { label: "All", value: "" },
    { label: "Closed", value: 1 },
    { label: "Not Closed", value: 0 },
  ];
  const filterForm = [
    {
      name: "searchBy",
      placeholder: "Select Duration",
      isRange: true,
      onchange: (data) => {
        formik.setFieldValue("to_date", data.endDate);
        formik.setFieldValue("from_date", data.startDate);
      },
      isVisible: true,
    },
    {
      name: "from_date",
      placeholder: "From Date",
      formik: formik,
      isInput: true,
      isVisible: formik.values.searchBy === "custom" ? true : false,
      type: "date",
    },
    {
      name: "to_date",
      placeholder: "To Date",
      formik: formik,
      isInput: true,
      isVisible: formik.values.searchBy === "custom" ? true : false,
      type: "date",
    },
    {
      name: "created_by",
      placeholder: "Select Created By",
      formik: formik,
      isSelect: true,
      options: usersDrd,
      onchange: (e) => formik.setFieldValue("created_by", e._id),
      getOptionLabel: (options) => options?.first_name,
      getOptionValue: (options) => options?._id,
      isVisible: true,
      value: formik?.values.created_by
        ? {
            _id: formik?.values.created_by,
            first_name: usersDrd?.find(
              ({ _id }) => _id === formik?.values.created_by
            )?.first_name,
          }
        : null,
    },
    {
      name: "job_num",
      placeholder: "Job Number",
      formik: formik,
      isInput: true,
      isVisible: true,
    },
    {
      name: "phone_num",
      placeholder: "Phone Number",
      formik: formik,
      isInput: true,
      isVisible: true,
    },
    {
      name: "industry_id",
      placeholder: "Select Industry",
      formik: formik,
      isSelect: true,
      options: industry?.industryDrd,
      onchange: (e) => formik.setFieldValue("industry_id", e._id),
      getOptionLabel: (options) => options?.name,
      getOptionValue: (options) => options?._id,
      isVisible: true,
      value: formik?.values.industry_id
        ? {
            _id: formik?.values.industry_id,
            name: industry?.industryDrd?.find(
              ({ _id }) => _id === formik?.values.industry_id
            )?.name,
          }
        : null,
    },
    {
      name: "is_closed",
      placeholder: "Select Job Type",
      formik: formik,
      isSelect: true,
      options: closedOption,
      onchange: (e) => formik.setFieldValue("is_closed", e.value),
      getOptionLabel: (options) => options?.label,
      getOptionValue: (options) => options?.value,
      isVisible: true,
      value: formik?.values.is_closed
        ? {
            value: formik?.values.is_closed,
            label: closedOption?.find(
              ({ value }) => value === formik?.values.is_closed
            )?.label,
          }
        : null,
    },
    {
      name: "vendor_id",
      placeholder: "Select Vendor",
      formik: formik,
      isSelect: true,
      options: geoVendorDrd,
      onchange: (e) => formik.setFieldValue("vendor_id", e.crm_id),
      getOptionLabel: (option) => option?.name,
      getOptionValue: (option) => option?.crm_id,
      isVisible: true,
      value: formik?.values.vendor_id
        ? {
            crm_id: formik?.values.vendor_id,
            name: geoVendorDrd?.find(
              ({ crm_id }) => crm_id === formik?.values.vendor_id
            )?.name,
          }
        : null,
    },
  ];
  const jobs = record?.records?.filter((record) => {
    const searchTextLower = searchText?.toLowerCase();
    function shouldSkipKey(key) {
      return (
        key.endsWith("_id") &&
        !Array.isArray(key) &&
        !(typeof key === "object" && key !== null)
      );
    }
    function searchInElement(element) {
      if (Array.isArray(element)) {
        return element.some((item) => searchInElement(item));
      } else if (typeof element === "object" && element !== null) {
        if (element.first_name && element.last_name) {
          const fullName =
            `${element.first_name} ${element.last_name}`.toLowerCase();
          if (fullName.includes(searchTextLower)) {
            return true;
          }
        }
        return Object.keys(element).some((key) => {
          if (shouldSkipKey(key)) return false;
          return searchInElement(element[key]);
        });
      } else {
        return element?.toString()?.toLowerCase().includes(searchTextLower);
      }
    }
    // Start the search in the current record
    return searchInElement(record);
  });

  const TagsCellRenderer = (data) => {
    const tags = data?.value?.map((tag) => (
      <span
        key={tag._id}
        style={{
          backgroundColor: tag.color,
          margin: "2px",
          color: "#fff",
          borderRadius: 2,
          padding: "0.2em 0.6em 0.3em",
          fontWeight: 700,
          fontSize: "85%",
          lineHeight: 1,
          whiteSpace: "nowrap",
          display: "inline-block",
        }}
      >
        {tag.name}
      </span>
    ));

    return (
      <div style={{ display: "flex", flexWrap: "wrap", width: "auto" }}>
        {tags}
      </div>
    );
  };
  const getRowHeight = (params) => {
    const rowHeight = 40; // minimum height of the row
    const cellContentHeight = 0; // you can set the height of the cell content if you want
    const data = params?.model;
    const numberOfLines = data?.complete_pickup_address
      ? Math.ceil(data?.complete_pickup_address?.length / 40)
      : 0;
    const addressHeight = rowHeight + numberOfLines * 30 + 20;
    if (data?.tags && data?.tags.length > 0) {
      const tagHeight = 20; // height of each tag
      const numTags = data.tags.length;
      if (addressHeight > rowHeight + tagHeight * numTags + cellContentHeight) {
        return addressHeight;
      } else {
        return rowHeight + tagHeight * numTags + cellContentHeight;
      }
    }
    if (addressHeight > rowHeight + cellContentHeight) {
      return addressHeight;
    } else {
      return rowHeight + cellContentHeight;
    }
  };
  // };
  const openNewTab = ({ id }) => {
    if (isButtonDisabled) {
      toast.info("Please wait for a second to open this job");
      return;
    }
    setIsButtonDisabled(true);
    window.open(`/jobs/update/preview/${id}`, "_blank");

    // Enable the button after 1000 milliseconds (1 second)
    setTimeout(() => {
      setIsButtonDisabled(false);
    }, 1000);

    // // const c = window.confirm(`Are you sure want to open the job ${jobNo}?`);
    // if (c) {

    // }
  };
  const columnDefs = [
    {
      field: "job_number",
      headerName: "Job #",
      renderCell: (params) => (
        <span
          onClick={() =>
            openNewTab({ id: params.row._id, jobNo: params.row.job_number })
          }
          className="text-blue-600 cursor-pointer hover:underline"
        >
          {params.row.job_number}
        </span>
      ),
      width: 60,
    },
    {
      headerName: "Created By",
      field: "created_by",
      flex: 1,
      minWidth: 100,
      cellClassName: "multiline-cell",
    },
    {
      headerName: "Created At",
      field: "createdAt",
      flex: 1,
      minWidth: 120,
      cellClassName: "multiline-cell",
    },

    { headerName: "Client", field: "name" },
    { headerName: "Industry", field: "industry_id", flex: 1, minWidth: 100 },
    { headerName: "Site Name", field: "site_name", flex: 1, minWidth: 100 },
    {
      headerName: "Scheduled On",
      field: "scheduled_on",
      flex: 1,
      minWidth: 130,
      cellClassName: "multiline-cell",
    },
    { headerName: "Status", field: "jobStatus" },
    {
      headerName: "Tags",
      field: "tags",
      renderCell: TagsCellRenderer,
      flex: 1,
      minWidth: 200,
    },
    { headerName: "Phone", field: "phone", flex: 1, minWidth: 120 },
    { headerName: "Call Type", field: "call_type", flex: 1, minWidth: 100 },
    {
      headerName: "Tech",
      field: "tech",
      minWidth: 150,
      cellClassName: "multiline-cell",
    },
    {
      headerName: "Address",
      field: "complete_pickup_address",
      width: 180,
      wrap: true,
      cellClassName: "multiline-cell",
    },
    {
      field: "actions",
      renderCell: (params) => (
        <div>
          {userPermission("Update Job") && (
            <FaEdit
              onClick={() => {
                navigate(`/jobs/update/${params.row._id}`);
              }}
              className="my_navIcon"
              title="Update Job"
            />
          )}
        </div>
      ),
      width: 80,
    },
  ];
  const isSelected = (val) => {
    if (selectedJob) {
      if (selectedJob === val._id) {
        return true;
      }
    }
    return false;
  };
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 100,
    page: 1,
  });
  const handlePageChange = (params) => {
    setPaginationModel({ pageSize: params.pageSize, page: params.page + 1 });
    dispatch(
      get_jobs_data({
        ...formik.values,
        page: +params.page + 1,
        size: params.pageSize,
      })
    );
  };
  return (
    <>
      <PageHeader
        route="Setting > Jobs"
        heading="Jobs Listing"
        onClick={() => navigate("/leads/add")}
        isAllowed={userPermission("Add Lead")}
      />
      {isLoading && <DotsLoader />}
      <Filter
        filterForm={filterForm}
        onReset={onReset}
        formik={formik}
        className="mt-2"
        showFilter={false}
      />
      {jobStatus?.jobStatuses.length > 0 && (
        <div className="!mt-2">
          <div className="flex flex-wrap !mt-1 ">
            {jobStatus?.jobStatuses?.map((data) => {
              const { _id, name, jobsCount } = data;
              return (
                <li
                  className="mb-2 list-none active mx-0.5"
                  key={_id}
                  onClick={() => {
                    setSelectedJob(_id);
                    formik.setFieldValue("job_status_id", _id);
                    dispatch(get_jobs_data({ job_status_id: _id }));
                  }}
                >
                  <div
                    className={`hover:bg-[#ddd] ${
                      isSelected(data)
                        ? "bg-white border-b-2 border-primary-100 text-base font-medium"
                        : ""
                    } flex flex-col items-center w-full p-1.25 text-black cursor-pointer`}
                  >
                    <span className="text-sm font-pop">
                      {name} {`(${jobsCount})`}
                    </span>
                  </div>
                </li>
              );
            })}
          </div>
        </div>
      )}
      <div className="bg-white my-3 border rounded mh-70-vh overflow-y-auto">
        <MUIDataTable
          columnDefs={columnDefs}
          items={jobs?.map((record, index) => {
            const {
              _id,
              job_number,
              name,
              phone,
              job_category_id,
              createdBy,
              createdAt,
              complete_pickup_address,
              scheduled_on,
              vendor_id,
              tags,
              job_status_id,
              industry_id,
              call_type,
            } = record;
            const site_name = record?.call_payload
              ? record?.call_payload[0]?.did_group_id
              : "";
            return {
              _id,
              counter: index + 1,
              records: record,
              job_number,
              job_type: job_category_id?.name,
              created_by: createdBy?.username,
              name,
              site_name,
              call_type,
              phone,
              jobStatus: job_status_id?.name,
              createdAt: dayjs(createdAt).format("ddd, MMM D, YYYY h:mm A"),
              scheduled_on:
                scheduled_on &&
                dayjs(scheduled_on).format("ddd, MMM D, YYYY h:mm A"),
              industry_id: industry_id?.name,
              tech: `${vendor_id?.first_name ? vendor_id?.first_name : ""} ${
                vendor_id?.last_name ? vendor_id?.last_name : ""
              }`,
              complete_pickup_address,
              tags,
            };
          })}
          onPaginationModelChange={handlePageChange}
          totalItems={record?.totalItems}
          searchText={searchText}
          setSearchText={setSearchText}
          paginationModel={paginationModel}
          isLoading={isLoading}
          gridOptions={getRowHeight}
          // autoHeight={true}
        />
      </div>
    </>
  );
};

export default Jobs;
