import React, { useRef } from "react";
import { Carousel } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { Button } from "../../../../components";
import { send_image_form } from "../../../../features/jobsSlice";

function ImageHistory(props) {
  const dispatch = useDispatch();
  const { formik, setImgSaveModal, setSingleImg, setImgViewModal } = props;
  const inputFile = useRef(null);
  const onFileChange = (e) => {
    let img = [];
    Array.from(e.target.files).forEach((file) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        img = img.concat(reader.result);
        formik.setFieldValue("attachments", img);
      };
      reader.readAsDataURL(file);
    });

    setImgSaveModal(true);
  };
  return (
    <div className="relative w-full h-full bg-white py-2.5 px-3 rounded-lg mt-2.5 border">
      <div className="flex flex-wrap mb-2 items-center gap-2">
        <h5 className="">Image History</h5>
        <div className="">
          <input
            style={{ display: "none" }}
            ref={inputFile}
            multiple
            onChange={onFileChange}
            type="file"
          />
          <Button
            text="Browse"
            className="btn ml-2"
            // backgroundColor="#f46424"
            color="#fff"
            onClick={() => inputFile.current.click()}
            variant="btn_submit"
          />
         {/*  <Button
            text="Send Link"
            className="ml-2"
            // backgroundColor="#f46424"
            color="#fff"
            variant="btn_submit"
            onClick={() => dispatch(send_image_form(formik.values.id))}
          /> */}
        </div>
      </div>
      {formik.values?.attachments.length > 0 && (
        // <div className="relative w-full flex gap-6 snap-x overflow-x-auto pb-4">
        //   {formik?.values?.attachments?.map((img, index) => {
        //     return (
        //       <div className="snap-center shrink-0 first:pl-1 last:pr-1">
        //         <img
        //           src={
        //             img.includes("merchants")
        //               ? `https://api.ecommcube.com/${img}`
        //               : img
        //           }
        //           className="shrink-0 h-[200px] bg-white"
        //           onClick={(e) => setSingleImg(e.target.currentSrc)}
        //         />
        //       </div>
        //     );
        //   })}
        // </div>

        <div className="col-12 px-0 mt-1">
          <Carousel
            nextLabel={null}
            prevLabel={null}
            indicators={false}
            style={{ height: 200 }}
            indicatorLabels={[formik?.values?.attachments?.length]}
            onClick={(e) => setSingleImg(e.target.currentSrc)}
          >
            {formik?.values?.attachments?.map((img, index) => {
              return (
                <Carousel.Item key={index}>
                  <img
                    className="d-block w-full"
                    src={
                      img.includes("merchants")
                        ? `${process.env.REACT_APP_BACKEND_URL}/${img}`
                        : img
                    }
                    alt="First slide"
                    style={{
                      height: 200,
                      objectFit: "contain",
                      alignSelf: "center",
                      marginRight: 10,
                    }}
                  />
                </Carousel.Item>
              );
            })}
          </Carousel>
          <p
            style={{
              backgroundColor: "rgba(244, 100, 36,0.4)",
              cursor: "pointer",
            }}
            className="text-center"
            onClick={() => setImgViewModal(true)}
          >
            Total Attachments {formik?.values?.attachments?.length}
          </p>
        </div>
      )}
    </div>
  );
}

export default ImageHistory;
